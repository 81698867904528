<template>
  <div>
    <basic-container>
    <!-- <el-col :span="4">
    <el-menu
      default-active="0"
      class="el-menu-vertical-demo"
      @open="handleOpen">
      <el-menu-item index="4">
        <span slot="title">待提交</span>
      </el-menu-item>
      <el-menu-item index="5">
        <span slot="title">待审核</span>
      </el-menu-item>
      <el-menu-item index="6">
        <span slot="title">已审核</span>
      </el-menu-item>
      <el-menu-item index="8">
        <span slot="title">已关闭</span>
      </el-menu-item>
      <el-menu-item index="7">
        <span slot="title"></span>
      </el-menu-item>
    </el-menu>
  </el-col>
  <el-col :span="20"> -->
    <avue-crud :option="tableOption"
                 :data="tableData"
                 v-model="formTable"
                 ref="crud"
                 :page.sync="page"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
        <template slot="batchState" slot-scope="scope">
            <el-switch v-model="scope.row.batchState" :inactive-value="0" :active-value="1"
            @change="change(scope.row)">
            </el-switch>
        </template>
        <template slot="menuRight">
            <el-select clearable v-model="productId" :placeholder="$t('MATERIAL NAME')" size="small" style="width:120px !important;marginRight: 5px;">
                <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-input v-model="batchCode" :placeholder="$t('BATCH2')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
            <el-select clearable v-model="batchState" :placeholder="$t('STATUS')" size="small" style="width:120px !important;marginRight: 5px;">
                <el-option :label="$t('NOT EFFECTIVE')" value="0"></el-option>
                <el-option :label="$t('EFFECTIVE')" value="1"></el-option>
            </el-select>
        </template>
      </avue-crud>
  <!-- </el-col> -->
        <!-- <ul style="width:150px;">
            <li style="height:40px;background:#bbb;lineHeight:40px;marginTop:3px;"><span style="marginLeft:15px;">岗位分类</span></li>
            <li style="height:40px;background:#bbb;lineHeight:40px;marginTop:3px;"><span style="marginLeft:15px;">工段分类</span></li>
            <li style="height:40px;background:#bbb;lineHeight:40px;marginTop:3px;"><span style="marginLeft:15px;">存货分类</span></li>
        </ul> -->
    </basic-container>
  </div>
</template>
<script>
import {list,treeList,add,remove,edit} from "@/api/workNo"
export default {
    data() {
        return {
            formTable: { name: "" },
            //工序
            productList: [],
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: '',
                pageCurrent: 1
            },
            //选择工序
            productId: "",
            //选择状态
            batchState: "",
            //手输批号
            batchCode: "",
            item: "",
            arrList: [],
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('MATERIAL NAME'),
                    hide: true,
                    editDisabled: true,
                    type: "tree",
                    dicData: [],
                    prop: "productId",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    change: function (value) {
                        console.log(this.$refs.crud.$refs.dialogForm);
                        this.$refs.crud.$refs.dialogForm.value.models = this.tableOption.column[0].dicData.find(v => {
                            return v.id === value.value;
                        }).models;
                    }.bind(this),
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                },{
                    label: this.$t('BATCH2'),
                    prop: "batchCode",
                    addDisplay: false,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER BATCH NUMBER'),
                        trigger: "blur"
                    }]
                },{
                    label: this.$t('SPECIFICATION AND MODEL'),
                    hide:true,
                    editRules: false,
                    prop: "models",
                    addDisabled:true,
                    editDisabled: true,
                    placeholder: this.$t('PRODUCT SPECIFICATION AND MODEL'),
                    rules: [{
                        required: false,
                        message: this.$t('PRODUCT SPECIFICATION AND MODEL'),
                        trigger: "blur"
                    }]
                },{
                    label: this.$t('MATERIAL NAME'),
                    display: false,
                    prop: "productName"
                },{
                    label: this.$t('BATCH NUMBER DATE'),
                    type: "date",
                    // valueFormat: "yyyy-MM-dd",
                    prop: "batchTime",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER BATCH NUMBER AND DATE'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('BATCH STATUS'),
                    prop: "batchState",
                    slot: true,
                    addDisplay: false,
                    editDisplay: false,
                    dicData: [{
                        label: this.$t('NOT EFFECTIVE'),
                        value: 0
                    }, {
                        label: this.$t('EFFECTIVE'),
                        value: 1
                    }]
                }, {
                    label: this.$t('CREATION DATE'),
                    display: false,
                    prop: "createTime",
                    type: "number",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE CREATION DATE'),
                        trigger: "blur"
                    }],
                    value: 0
                }, {
                    label: this.$t('CREATOR'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "createUserName"
                }]
            }
        }
    },
    created() {
      let productObj = this.request({
        url : this.businessUrl + "product/selectAll",
        method: "get",
        data: {
        }
      });
      productObj.then(res => {
        res.data.data = res.data.data.filter(v => {
          return v.enableBatch == 1;
        });

        this.productList = res.data.data;
        this.tableOption.column[0].dicData = res.data.data;
      });
      treeList().then(res => {
        this.arrList = res.data.data;
        this.item = res.data.data[0].id;
        this.list(this.item);
         this.page.total = res.data.data.total
      })
    },
    methods: {
        change(row) {
            edit(row.id,row).then((res) => {
                if (res.data.code == "0000") {
                    this.$message({
                        type: "success",
                        message: "状态修改成功"
                    });
                } else {
                  this.$message({
                    type: "error",
                    message: "状态修改失败"
                  });
                }
              this.list();
            })
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        handleUpdate(row,index,done) {
          delete row.productId;
          edit(this.item.id,row).then(res => {
            this.list(this.item);
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success"
            })
          })
          done();
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
      })
        .then(() => {
          remove(row.id).then(res => {
            this.list(this.item);
            this.$message({
              showClose: true,
                message: this.$t('DeleteSuccess'),
              type: "success"
            });
          });
        })
        .catch(() => { });
        },
        handleOpen() {
          alert("loading");
        },
        handleSave(parmas,done) {
          function time(time = +new Date()) {
            var date = new Date(time + 8 * 3600 * 1000); // 增加8小时
            return date.toJSON().substr(0, 19).replace('T', ' ');
          }
          parmas.batchDate = time(parmas.batchTime)
          add(this.item.id,parmas).then((response) => {
            if (response.data.code == '0000') {
              this.$message({
                message: this.$t('AddSuccess'),
                type:"success"
              })
            }

            this.list(this.item);
            done();
          })
        },
        refreshChange() {
          this.list();
        },
        list(item){
          this.item = item;
          console.log(this.productId,this.batchState,this.batchCode);
          list(this.page.pageCurrent,this.page.pageSize,this.productId,this.batchState,this.batchCode).then(res => {
            res.data.data.items.forEach(v => {
              v.productResponse ? v.productName = v.productResponse.name : "";
              v.productResponse ? v.models = v.productResponse.models : "";
              v.productResponse ? v.status = v.productResponse.status : "";
            });
              if (res.data.data == null){
                  this.tableData = [];
                  this.page.total = 0;
              } else {
                  this.tableData = res.data.data.items;
                  this.page.total = res.data.data.total;
              }

          });
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style>
    /* .el-menu-vertical-demo {
    } */
</style>
